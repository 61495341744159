import React from 'react';
import { SEO, Layout } from "components"
import { StaticQuery, graphql } from "gatsby"

import Block from './Block';

import styles from './styles.module.scss';

interface Rate {
  frontmatter: {
    title: string;
    price: number;
    content: string;
  }
}

interface RateBlock {
  title: string;
  price: number;
  content: string;
}

const Rates = () => (
  <Layout>
    <SEO />
    <div className={styles.ratesPage}>
      <div className={styles.ratesTable}>
      <StaticQuery
        query={graphql`
        query RateQuery {
          allMarkdownRemark (
            filter: { fileAbsolutePath: {regex : "\/rates/"} }
          ) {
            nodes {
              frontmatter {
                title
                price
                content
              }
            }
          }
        }`}
        render={data => {
          const rates = data.allMarkdownRemark.nodes.map((rate: Rate) => (
            { title: rate.frontmatter.title, price: rate.frontmatter.price, content: rate.frontmatter.content }
          ))
          return (
            <div id="rates" className={styles.rateRow}>
              {rates && rates.map((rb: RateBlock) => (
                <Block key={rb.title} { ...rb } />
              ))}
          </div>
          )
        }}
      />
      </div>
    </div>
  </Layout>
)

export default Rates;
